<template>
  <section class="ads-management-section">
    <DefaultContainer class="ads-management-container">
      <slot />
    </DefaultContainer>
  </section>
</template>

<style scoped lang="scss">
  .ads-management-section {
    padding: 0 320px;
  }

  .ads-management-container {
    background: $base100;
    padding: 20px;
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .ads-management-section {
      padding: 0 110px;
    }
  }

  @media screen and (max-width: ($tablet + 'px')) {
    .ads-management-section {
      padding: 0 64px;
    }
  }

  @media screen and (max-width: ($mobileLarge + 'px')) {
    .ads-management-section {
      padding: 0;
    }

    .ads-management-container {
      padding: 32px 16px 0;
    }
  }
</style>
